<template>
  <v-dialog
    v-model="dialogBaixaVenda"
    @click:outside="fecharBaixaVenda"
    @keydown.esc="fecharBaixaVenda"
    width="70vw"
    scrollable
  >
    <v-card tile>
      <!-- HEADE DIALOG -->
      <v-card-title class="px-6 toolbar">
        <span class="white--text">
          {{ $tc("global.venda") }}
        </span>
        <span class="mx-2 toolbar white--text">{{ venda.numero + " - " }}</span>
        <span class="white--text">
          {{
            venda.condicao_pagamento === 2
              ? $tc("global.aPrazo")
              : $tc("global.aVista")
          }}
        </span>

        <v-spacer></v-spacer>

        <span class="white--text pr-4">
          {{ venda.data | dateFormat("dd/MM/yyyy") }}
        </span>

        <v-btn c icon dark @click="fecharBaixaVenda">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- CORPO DIALOG -->
      <v-card-text class="pa-6">
        <!-- DADOS DO CLIENTE COM DEPOSITO VALOR E DESCONTO -->
        <v-card
          width="100%"
          outlined
          class="d-flex justify-space-between align-center pa-4"
        >
          <div>
            <span class="text-h6 font-weight-bold">
              {{ $tc("global.cliente") }}:
            </span>
            <span class="subtitle-1 ml-2">{{ venda.cliente_nome }}</span>
          </div>

          <div>
            <span class="subtitle-1">
              <span class="text-h6 font-weight-bold"
                >{{ $tc("global.deposito") }}:
              </span>
              {{ venda.deposito_nome }}
            </span>
          </div>

          <div>
            <span class="subtitle-1">
              <span class="text-h6 font-weight-bold"
                >{{ $tc("global.valor") }}:
              </span>
              <span v-if="venda.moeda_sigla === 'G$'">
                {{ venda.moeda_sigla }} {{ venda.valor | guarani }}
              </span>
              <span v-else>
                {{ venda.moeda_sigla }} {{ venda.valor | currency }}
              </span>
            </span>
          </div>

          <div>
            <span class="subtitle-1">
              <span class="text-h6 font-weight-bold"
                >{{ $tc("global.desconto") }}:
              </span>
              <span v-if="venda.moeda_sigla === 'G$'">
                {{ venda.moeda_sigla }} {{ venda.desconto | guarani }}
              </span>
              <span v-else>
                {{ venda.moeda_sigla }} {{ venda.desconto | currency }}
              </span>
            </span>
          </div>
        </v-card>

        <BaixaVenda
          v-if="venda.condicao_pagamento === 1"
          :venda="venda"
          :formas-pagamentos.sync="formas_pagamentos"
          :valor-restante.sync="totalRestante"
          :disable-troco.sync="disable_troco"
          :troco_dolar.sync="troco_dolar"
          :troco_guarani.sync="troco_guarani"
          :troco_real.sync="troco_real"
          :gerar_antecipo.sync="gerar_antecipo"
        ></BaixaVenda>
        <!-- QUANDO FOR PARCELADO -->
        <v-data-table
          v-if="venda.condicao_pagamento == 2"
          :headers="headers"
          :items="venda_parcelas"
          :items-per-page="-1"
          :sort-desc="true"
          class=""
          hide-default-footer
        >
          <template v-slot:item.numero_parcela="{ item }">
            <span v-if="item.entrega_inicial"> Entrega inicial </span>
            <span v-else>
              {{ item.numero_parcela }}
            </span>
          </template>

          <template v-slot:item.total_parcelas="{ item }">
            <span v-if="!item.entrega_inicial">
              {{ item.total_parcelas }}
            </span>
          </template>

          <template v-slot:item.vencimento="{ item }">
            <span v-if="!item.entrega_inicial">
              {{ item.vencimento | dateFormat("dd/MM/yyyy") }}
            </span>
          </template>

          <template v-slot:item.valor="{ item }">
            <span v-if="venda.moeda_sigla === 'G$'">
              {{ venda.moeda_sigla }} {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ venda.moeda_sigla }} {{ item.valor | currency }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions class="mr-4 pa-4">
        <v-dialog v-model="dialogDevolucao" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="button_2"
              class="white--text mr-10"
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>mdi-arrow-u-left-top</v-icon>
              {{ $tc("global.retornarVendedor") }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <v-icon left>mdi-alert</v-icon>
              {{ $tc("global.desejaDevolver") }}
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="motivo_devolucao"
                :label="$tc('global.motivo') + ' ' + $tc('global.devolucao')"
                outlined
                required
              ></v-text-field>
              <span class="text-caption">
                {{ $tc("global.mincaracteres", 10) }}
              </span>
            </v-card-text>

            <v-card-actions>
              <v-btn color="error" text @click="dialogDevolucao = false">
                {{ $tc("global.cancelar") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                small
                color="button_2"
                :disabled="semMotivo"
                class="white--text"
                @click="retornarVenda"
              >
                <v-icon left>mdi-arrow-u-left-top</v-icon>
                {{ $tc("global.devolver") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>
        <PdfDialogComp
          v-if="pdfDialog"
          :pdfDialog.sync="pdfDialog"
          :pdfData="pdfData"
          @close="$emit('update:dialogBaixaVenda', false)"
          title="PDF"
        />

        <v-card
          flat
          class="font-weight-bold d-flex pa-0 py-1 align-center pr-3"
        >
          {{ $tc("global.gerarFatura") }}
          <v-switch
            class="ma-0 ml-3 pa-0"
            hide-details
            :false-value="false"
            :true-value="true"
            :disabled="
              forceGerarFatura || venda.tipo_venda == 'PISCINA' ? true : false
            "
            v-model="gerarFatura"
          ></v-switch>
        </v-card>

        <v-btn
          @click.stop="faturarVenda"
          :disabled="faturarDisabled"
          small
          :loading="loading"
          class="white--text"
          color="button_1"
        >
          <v-icon left>mdi-cash-fast</v-icon>
          {{ $tc("global.faturar") }}
        </v-btn>
      </v-card-actions>
      <DialogPrinter
        v-if="dialogPrinter"
        :dialogPrinter.sync="dialogPrinter"
        :fatura_id.sync="fatura_id"
        :venda_id.sync="venda.id"
        :possui_contrato="venda.possui_contrato"
        :venda_condicao_pagamento.sync="venda.condicao_pagamento"
        @fechar-dialog="fecharDialog"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import {
  fechaBaixaVenda,
  retornaVenda,
  faturaVenda,
  faturaVendaPiscina,
} from "@/api/vendas/vendas.js";
import { index } from "@/api/empresas/empresa_variaveis.js";
import { dataImpressaoFatura, fetchFatura } from "@/api/faturas/faturas.js";
import { mapState } from "vuex";
const importFatura = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples.js"
  );
const importFaturaAutoImpressor = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples_auto_impressor.js"
  );

export default {
  name: "DialogBaixaVenda",

  props: {
    dialogBaixaVenda: {
      type: Boolean,
      default: false,
    },
    venda: {
      type: Object,
    },
    venda_parcelas: {
      type: Array,
    },
  },

  components: {
    BaixaVenda: () => import("./BaixaVenda.vue"),
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
    DialogPrinter: () => import("./DialogPrinter.vue"),
  },

  data() {
    return {
      loading: false,
      dialogDevolucao: false,
      motivo_devolucao: "",
      gerarFatura: false,
      formas_pagamentos: [],
      dialogPrinter: false,
      selectedFormaPagamento: null,
      totalRestante: null,
      fatura_id: null,
      pdfDialog: false,
      pdfData: null,
      variaveis: [],
      variavel_obrigatorio_fatura: {},
      contrato: null,
      disable_troco: false,
      troco_dolar: 0,
      troco_guarani: 0,
      troco_real: 0,
      gerar_antecipo: 0,
    };
  },

  computed: {
    caixa_id() {
      return this.$route.params.caixa_id;
    },

    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),

    headers() {
      return [
        {
          text: this.$tc("global.numero") + " " + this.$tc("global.parcela"),
          value: "numero_parcela",
        },

        {
          text: this.$tc("global.total") + " " + this.$tc("global.parcela", 2),
          value: "total_parcelas",
        },
        {
          text: this.$tc("global.vencimento"),
          value: "vencimento",
        },
        {
          text: this.$tc("global.valor") + " " + this.$tc("global.parcela"),
          value: "valor",
        },
      ];
    },

    semMotivo() {
      let result = false;

      if (
        this.motivo_devolucao === "" ||
        this.motivo_devolucao === null ||
        this.motivo_devolucao === undefined ||
        this.motivo_devolucao.length < 10
      ) {
        result = true;
      }

      return result;
    },

    faturarDisabled() {
      let result = false;

      if (this.totalRestante >= 0.01) {
        result = true;
      }
      if (this.disable_troco) {
        result = true;
      }
      return result;
    },

    forceGerarFatura() {
      let result = false;

      this.formas_pagamentos.find((forma_pagamento) => {
        if (
          forma_pagamento.forma_de_pagamento_id === 6 ||
          forma_pagamento.forma_de_pagamento_id === 7
        ) {
          result = true;
        }
      });

      if (
        this.venda.tipo_venda != "PISCINA" &&
        (this.venda.condicao_pagamento === 2 ||
          this.variavel_obrigatorio_fatura.valor === "1")
      ) {
        result = true;
      }

      return result;
    },
  },

  watch: {
    forceGerarFatura() {
      if (this.forceGerarFatura) {
        this.gerarFatura = true;
      }
    },
  },

  methods: {
    fecharDialog() {
      this.$emit("update:dialogBaixaVenda", false);
      this.$emit("fetch-vendas-caixa");
      this.dialogPrinter = false;
    },

    fecharBaixaVenda() {
      this.$emit("update:dialogBaixaVenda", false);
      fechaBaixaVenda(this.venda.id)
        .then(() => {
          this.$emit("fetch-vendas-caixa");
        })
        .catch(() => {});
    },

    retornarVenda() {
      let devolucao = {};
      devolucao.motivo = this.motivo_devolucao;

      retornaVenda(this.venda.id, devolucao)
        .then(() => {
          this.$emit("update:dialogBaixaVenda", false);
          this.$emit("fetch-vendas-caixa");
        })
        .catch(() => {});
    },

    async faturarVenda() {
      if (this.venda.tipo_venda === "SIMPLES") {
        await this.vendaSimples();
      }
      if (this.venda.tipo_venda === "PISCINA") {
        await this.vendaPiscina();
      }
    },

    vendaSimples() {
      this.loading = true;
      let venda = {};

      venda.venda_id = this.venda.id;
      venda.caixa_af_id = this.caixa_af.id;
      venda.gerar_fatura = this.gerarFatura;
      venda.formas_pagamentos = this.formas_pagamentos;
      venda.gerar_antecipo = this.gerar_antecipo;
      venda.troco_dolar = this.troco_dolar;
      venda.troco_guarani = this.troco_guarani;
      venda.troco_real = this.troco_real;
      if (venda.gerar_antecipo == true) {
        venda.total_restante = this.totalRestante;
      }

      return faturaVenda(venda)
        .then((response) => {
          if (response.status === 200) {
            if (this.gerarFatura) {
              //   this.generateFatura(

              (this.fatura_id = response.data.data.fatura_id),
                //     false,
                //     false,
                //     false
                // "fatura"
                //   );
                // }
                (this.dialogPrinter = true);
            } else {
              this.dialogPrinter = true;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    vendaPiscina() {
      this.loading = true;
      let venda = {};
      let troco = {};

      venda.venda_id = this.venda.id;
      venda.caixa_af_id = this.caixa_af.id;
      venda.gerar_fatura = this.gerarFatura;
      venda.formas_pagamentos = this.formas_pagamentos;
      venda.contrato = this.contrato;
      venda.gerar_antecipo = this.gerar_antecipo;
      venda.troco_dolar = this.troco_dolar;
      venda.troco_guarani = this.troco_guarani;
      venda.troco_real = this.troco_real;
      if (venda.gerar_antecipo == true) {
        venda.total_restante = this.totalRestante;
      }

      return faturaVendaPiscina(venda)
        .then((response) => {
          if (response.status === 200) {
            if (this.gerarFatura) {
              //   this.generateFatura(

              (this.fatura_id = response.data.data.fatura_id),
                //     false,
                //     false,
                //     false
                // "fatura"
                //   );
                // }
                (this.dialogPrinter = true);
            } else {
              this.dialogPrinter = true;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async isAutoImpressor(fatura_id) {
      const fatura = await fetchFatura(fatura_id);
      return fatura.timbrado_auto_impressor;
    },

    async generateFatura(fatura_id, print, download, unica, tipo) {
      this.loading = true;

      try {
        let autoImpressor = await this.isAutoImpressor(fatura_id);

        if (autoImpressor) {
          importFaturaAutoImpressor()
            .then((module) => {
              let generate = module.default;
              generate(fatura_id, true, false, false, "fatura").then(
                (response) => {
                  this.pdfData = response;
                  this.pdfDialog = true;
                  dataImpressaoFatura(fatura_id)
                    .then(() => {})
                    .catch(() => {});
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          importFatura()
            .then((module) => {
              let generate = module.default;
              generate(fatura_id, print, download, unica, tipo).then(
                (response) => {
                  this.pdfData = response;
                  this.pdfDialog = true;
                  dataImpressaoFatura(fatura_id)
                    .then(() => {})
                    .catch(() => {});
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },

  async mounted() {
    this.variaveis = await index(`?empresa_id=${this.venda.empresa_id}`);
    this.variavel_obrigatorio_fatura = this.variaveis.find(
      (variavel) => variavel.campo === "OBRIGATORIO_EMISSAO_FATURA"
    );
    if (
      (this.venda.condicao_pagamento === 2 ||
        this.variavel_obrigatorio_fatura.valor === "1") &&
      this.venda.tipo_venda != "PISCINA"
    ) {
      this.gerarFatura = true;
    }
  },
};
</script>

<style></style>
